<template>
	<Item
		icon="material-symbols:add-circle-outline-rounded"
		:isSelected="isSelected"
		:usage="clickHandler"
	/>
</template>

<script>
import Item from './Item.vue';
import { globalBus } from '../../GlobalEventBus';

const PAGES_WITH_SLOT_CREATE = [
	'calendar',
	'calendar-schedule',
]

export default {
	name: 'CreateSlotItem',
	props: {
        isSelected: Boolean,
	},
	components: {
		Item,
	},

	data() {
		return {
			isTouched: false,
			isFastMode: false,
			staticMenuIsVisible: true,
			isPreparationForDelete: false,
		};
	},

	mounted() {
		const supportsPointerEvents = 'PointerEvent' in window;

		if (supportsPointerEvents) {
			this.$el.addEventListener('pointerdown', (e) => {
				this.$el.setPointerCapture(e.pointerId);
				this.pointerDownHandler(e)
			});
			this.$el.addEventListener('pointerup', this.pointerUpHandler);
			this.$el.addEventListener('pointercancel', this.pointerUpHandler);
			this.$el.addEventListener('pointermove', this.pointerMoveHandler);
			this.$el.addEventListener('touchstart', (e) => e.preventDefault());
		} else {
			this.$el.addEventListener('mousedown', (e) => {
				this.$el.setPointerCapture(e.pointerId);
				this.pointerDownHandler(e)
			});
			this.$el.addEventListener('mouseup', this.pointerUpHandler);
			this.$el.addEventListener('mousemove', this.pointerMoveHandler);
			this.$el.addEventListener('touchstart', (e) => e.preventDefault());
		}
	},

	methods: {
		dispatchNewEvent(event, eventName) {
			let target = document.getElementById("create-slot")
			if (!target)
				return
			const pointerDownEvent = new PointerEvent(eventName, {
				bubbles: true,
				clientX: event.clientX,
				clientY: event.clientY
			});
			target.dispatchEvent(pointerDownEvent);
		},

		pointerDownHandler(event){
			this.isTouched = true
            if (!this.accessToCreateSlot())
                return
            
			setTimeout(() => {
				if (this.isTouched) {
					if (window.navigator.vibrate)
						window.navigator.vibrate(65)
					this.isFastMode = true
					this.$store.dispatch('set-test-event', event)
					setTimeout(() => this.dispatchNewEvent(event, 'pointerdown'), 50)
				}
			}, 350)
        },
        pointerMoveHandler(event){
			if (!this.accessToCreateSlot())
                return

			if (this.isFastMode){
				this.dispatchNewEvent(event, 'pointermove')
				if (this.staticMenuIsVisible) {
					this.staticMenuIsVisible = false
					globalBus.$emit("static-menu", "update-visible", {visible:false})
				}
			}
        },
        pointerUpHandler(event){
			this.isTouched = false
            
			if (!this.accessToCreateSlot())
                return
			if (this.isFastMode) {
				this.dispatchNewEvent(event, 'pointerup')
				this.$store.dispatch('set-test-event', null)
				if (!this.staticMenuIsVisible) {
					this.staticMenuIsVisible = true
					globalBus.$emit("static-menu", "update-visible", {visible:true})
				}
			} else {
				this.clickHandler()
			}
			this.isFastMode = false
        },

		clickHandler(){
            globalBus.$emit('slot-create', 'show', {})
        },

        accessToCreateSlot(){
            if (!PAGES_WITH_SLOT_CREATE.includes(this.$route.name))
                return false
        
            const calendar = this.$store.getters.currentCalendar()
			if (!calendar || !calendar.isEditable)
				return false
			
            return true
        }
	},
};
</script>

<style scoped>
</style>